<template>
	<div class="Extension">
		<div class="Ext_Top">
			<div class="Ext_Top_item">
				<p>推广等级:<span>Lv{{UserData.promotion_level}}</span></p>
			</div>
			<div class="Ext_Top_item">
				<p>推广奖励:<span>{{ExtRebate}}</span>%</p>
			</div>
			<div class="Ext_Top_item">
				<p>成功推广人数:<span>{{UserData.be_invite_number}}</span>人</p>
			</div>
			<div class="Ext_Top_item">
				<p>下级累计充值:<span><div class="coins"></div>{{UserData.gain}}</span></p>
			</div>
		</div>
		<div class="Ext_center">
			<van-field center clearable label="推广链接" readonly :value="InviteLink">
				<template #button>
					<van-button size="small" type="info" v-clipboard:copy="InviteLink" v-clipboard:success="copy" v-clipboard:error="onError">复制</van-button>
				</template>
			</van-field>
			<van-field class="" center clearable label="推广码" readonly :value="UserData.invite_code">
				<template #button>
					<van-button size="small" type="info" v-clipboard:copy="UserData.invite_code" v-clipboard:success="copy"
					 v-clipboard:error="onError">复制</van-button>
				</template>
			</van-field>
			<div></div>
		</div>
		<div class="Ext_bottom">
			<div class="Ext_tit">推广规则</div>
			<p>1.每个新用户通过推广链接进入网站充值成功，都会给推广人带来固定比例的佣金返利，佣金比例以页面显示为准。<br />

				2.如果用户通过你的推广链接进入网站充值并未给你带来佣金返利，代表此用户不是新用户。<br />

				3.严禁通过不正当方式刷取本活动奖励，任何作弊行为一经发现，将直接扣除作弊用户获得的所有佣金返利；情节严重的，将直接采取封号等处理方式。<br />

				声明：wanskins将根据实际运营情况保留随时调整返佣比例的权利，以上活动最终解释权归wanskins所有!</p>
		</div>

		<!-- <div class="key_box">
			<div class="key_box_tit">推广活动</div>
			<div class="key_tit">
				你通过累积活动获得了<span>{{welfare_key_total}}</span>把钥匙<br />
				<p v-for="(item,index) in welfare_promotion" :key="index">{{item.box.name}}钥匙<span>{{item.my_times}}</span>把{{index | indexGL}}</p>
			</div>
			<div class="key_list">
				<div class="key_item" v-for="(item,index) in welfare_promotion" :key="index">
					<p>{{item.name}}</p>
					<i>------------------------</i>
					<span>+1🔑{{item.box.name}}箱子钥匙</span>
				</div>

			</div>
		</div> -->

		<div class="Grade">
			<div class="Grade_tit">推广等级说明</div>
			<div class="Grade_item">
				<div>等级</div>
				<div>下一级需要</div>
				<div>您的收益比</div>
			</div>
			<div class="Grade_item" v-for="(item,index) in GradeList" :key="index + 'a'">
				<div>{{item.name}}</div>
				<div><div class="coins"></div>{{item.total}}</div>
				<div>{{item.rebate}}%</div>
			</div>
		</div>
		
		<!-- 推广等级升级要求遮罩层 -->
		<van-overlay :show="Gradeshow == index" :key="index" v-for="(item,index) in GradeList">
			<div class="wrapper">
				<div class="LogOut_box">
					<div class="close" @click="Gradeshow = null"><img src="@/assets/images/public/close.png" alt=""></div>
					<div class="tit">Lv{{item.level}}升级要求</div>
					<div class="text">{{item.description}}</div>
				</div>
			</div>
		</van-overlay>
		
		<!-- <div class="Subordinate">
			<div class="Subordinate_tit">收益明细</div>
			<div class="Subordinate_list">
				<div class="Subordinate_item">
					<p>时间</p>
					<p>用户昵称</p>
					<p>充值金额</p>
					<p>收益比例</p>
					<p>收益</p>
				</div>
				<div class="Subordinate_item" v-for="(item,index) in benefitList" :key="index">
					<p>{{item.created_at}}</p>
					<p>
						<span class="name">{{item.next_user_name}}</span>
					</p>
					<p><img src="@/assets/images/public/Gold.png" alt="">{{item.charge_bean}}</p>
					<p>{{item.rate_text}}</p>
					<p><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</p>
				</div>
			</div>
			<el-pagination background layout="prev, pager, next" :total="benefitTotal" :page-size="benefitPer_page" @current-change="benefitHandleCurrentChange" prev-text="上一页" next-text="下一页" small>
			</el-pagination>
		</div> -->
		
		<div class="Subordinate">
			<div class="Subordinate_tit">收益统计</div>
			<div class="type-list">
				<div class="item" :class="{'on': selectType==1}" @click="selectType=1">日统计</div>
				<div class="item" :class="{'on': selectType==2}" @click="selectType=2">周统计</div>
			</div>
			
			<div class="Subordinate_list" v-show="selectType==1">
				<table>
					<tr>
						<td>时间</td>
						<td>推广人数</td>
						<td>充值人数</td>
						<td>充值金额</td>
						<td>收益</td>
					</tr>
					<tr v-for="(item,index) in benefitStatList" :key="item.id">
						<td>{{item.datetime}}</td>
						<td>{{item.invite_count}}</td>
						<td>{{item.recharge_count}}</td>
						<td>
							<div class="price">
								<div class="coins"></div>
								{{item.charge_bean}}
							</div>
						</td>
						<td>
							<div class="price">
								<div class="coins"></div>
								{{item.bean}}
							</div>
						</td>
					</tr>
				</table>
			</div>
			<el-pagination v-show="selectType==1" background layout="prev, pager, next" :total="benefitStatTotal" :page-size="benefitStatPer_page" @current-change="benefitStatHandleCurrentChange" prev-text="上一页" next-text="下一页" small>
			</el-pagination>
			
			<div class="Subordinate_list" v-show="selectType==2">
				<table>
					<tr>
						<td>时间</td>
						<td>推广人数</td>
						<td>充值人数</td>
						<td>充值金额</td>
						<td>收益</td>
					</tr>
					<tr v-for="(item,index) in benefitStatWeekList" :key="item.id">
						<td>{{item.start_date}}<br/>至<br/>{{item.end_date}}</td>
						<td>{{item.invite_count}}</td>
						<td>{{item.recharge_count}}</td>
						<td>
							<div class="price">
								<div class="coins"></div>
								{{item.charge_bean}}
							</div>
						</td>
						<td>
							<div class="price">
								<div class="coins"></div>
								{{item.bean}}
							</div>
						</td>
					</tr>
				</table>
			</div>
			<el-pagination v-show="selectType==2" background layout="prev, pager, next" :total="benefitStatWeekTotal" :page-size="benefitStatWeekPer_page" @current-change="benefitStatWeekHandleCurrentChange" prev-text="上一页" next-text="下一页" small>
			</el-pagination>
		</div>
		
	</div>
</template>

<script>
	import {
		Promote,
		Be_inviter,
		userBenefit,
		userBenefitStat
	} from '@/network/api.js'
	export default {
		name: 'Extension',
		data() {
			return {
				UserData: {}, //推广个人数据
				ExtRebate: '', //个人返佣比例
				GradeList: [], //推广等级列表
				Gradeshow: null, //推广等级升级要求遮罩层
				SubordinateList: [], //推广明细列表
				total: 0, //推广明细列表总数据
				per_page: 0, //一页数据数量
				PageNum: 1, //页码
				welfare_promotion: [], //钥匙列表
				welfare_key_total:0,//钥匙总量
				
				
				benefitList: [],
				benefitTotal: 0,
				benefitPer_page: 10,
				benefitLoadingShow: true,
				
				selectType:1,
				
				benefitStatList: [],
				benefitStatTotal: 0,
				benefitStatPer_page: 10,
				benefitStatLoadingShow: true,
				
				benefitStatWeekList: [],
				benefitStatWeekTotal: 0,
				benefitStatWeekPer_page: 10,
				benefitStatWeekLoadingShow: true,
			}
		},
		created() {
			this.GetPromote()
			// this.GetBe_inviter()
			// this.userBenefit(1)
			this.userBenefitStat(1,1)
			this.userBenefitStat(1,2)
		},
		watch: {
			benefitList(val) {
				this.benefitLoadingShow = false
			},
			benefitStatList(val) {
				this.benefitStatLoadingShow = false
			},
			benefitStatWeekList(val) {
				this.benefitStatWeekLoadingShow = false
			}
		},
		filters: {
			indexGL(v) {
				if (v == 4) {
					return '。'
				} else {
					return ','
				}
			}
		},

		methods: {
			// 收益统计分页
			benefitStatWeekHandleCurrentChange(val) {
				this.userBenefitStat(val,2)
			},
			
			// 收益统计分页
			benefitStatHandleCurrentChange(val) {
				this.userBenefitStat(val,1)
			},
			
			//收益统计
			userBenefitStat(val, type) {
				this.benefitLoadingShow = true
				userBenefitStat(val, type).then((res) => {
					if(type==1){
						this.benefitStatList = res.data.data.users.data
						this.benefitStatTotal = res.data.data.users.total
						this.benefitStatPer_page = res.data.data.users.per_page
					}else{
						this.benefitStatWeekList = res.data.data.users.data
						this.benefitStatWeekTotal = res.data.data.users.total
						this.benefitStatWeekPer_page = res.data.data.users.per_page
					}
					
				})
			},
			// 收益明细分页
			benefitHandleCurrentChange(val) {
				this.userBenefit(val)
			},
			
			//收益明细
			userBenefit(val) {
				this.benefitLoadingShow = true
				userBenefit(val).then((res) => {
					this.benefitList = res.data.data.user_reward_logs.data
					this.benefitTotal = res.data.data.user_reward_logs.total
					this.benefitPer_page = res.data.data.user_reward_logs.per_page
				})
			},
			//个人推广数据
			GetPromote() {
				Promote().then((res) => {
					// console.log(res.data.data)
					// this.welfare_promotion = res.data.data.welfare_promotion
					this.UserData = res.data.data.user
					this.welfare_key_total = res.data.data.user.welfare_key_total
					this.ExtRebate = res.data.data.user.promotion.rebate
					this.GradeList = res.data.data.list
				})
			},

			//复制文本
			copy(e) {
				// console.log(e)
				this.$notify({
					type: 'success',
					message: '复制成功'
				})
			},
			onError(e) {
				// console.log(e)
				this.$notify({
					type: 'danger',
					message: '复制失败'
				})
			},

			//推广升级要求遮罩层
			ChangeGradeshow(index) {
				this.Gradeshow = index
			},

			//推广明细列表分页
			handleCurrentChange(val) {
				// console.log(val)
				this.PageNum = val
				this.GetBe_inviter()
			},

			//推广明细列表
			GetBe_inviter() {
				Be_inviter(this.PageNum).then((res) => {
					// console.log(res.data.data)
					this.total = res.data.data[0].total
					this.per_page = res.data.data[0].per_page
					// this.SubordinateList = res.data.data[0].data
				})
			}
		},
		computed: {
			//推广链接
			InviteLink() {
				return 'https://www.wanskins.com/?ref=' + this.UserData.invite_code
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.Extension {
		width: 100%;
		padding: 10px;
		box-sizing: border-box;

		.Ext_Top {
			width: 100%;
			border-radius: 4px;
			display: flex;
			flex-wrap: wrap;
			padding: 0;
			gap: 4px;
			font-size: 12px;
			.Ext_Top_item {
				width: 175px;
				height: 50px;
				display: flex;
				align-items: center;
				color: #fff;
				padding-left: 10px;
				box-sizing: border-box;
				background: #1D1D28;
				white-space: nowrap;
				p {
					@include Scenter;
					span {
						font-weight: bold;
						margin-left: 5px;
						@include Scenter;

						.coins {
							width: 14px;
							height: 14px;
							margin-right: 2px;
						}
					}
				}

			}
		}

		.Ext_center {
			width: 100%;
			background: #1D1D28;
			border-radius: 4px;
			margin-top: 10px;
			.van-cell {
				background: none;

				.van-field__label {
					width: 50px;
					color: #fff;
					font-size: 12px;
				}

				.van-field__control {
					color: #fff;
				}

				&.smll {
					width: 70%;
				}
			}

			.van-cell::after {
				display: none;
			}
		}

		.Ext_bottom {
			width: 100%;
			margin-top: 12px;
			padding-top: 12px;
			padding-bottom: 20px;
			background: #1D1D28;
			border-radius: 4px;
			overflow: hidden;
			color: #fff;

			.Ext_tit {
				font-size: 16px;
				font-weight: bold;
				text-align: center;
				margin: 0 0 10px;
			}

			p {
				width: 100%;
				padding: 0 20px;
				box-sizing: border-box;
				line-height: 1.7em;
				font-size: 12px;
				color: #bbb;
			}
		}

		.key_box {
			width: 100%;
			background: #20222b;			border-radius: 4px;
			margin: 10px 0;
			padding: 10px;
			box-sizing: border-box;
			.key_box_tit{
				font-size: 16px;
				font-weight: bold;
				text-align: center;
				color: #fff;
			}
			.key_tit {
				text-align: center;
				line-height: 1.75em;
				font-size: 14px;
				margin-top: 5px;
				color: #c6c4d8;

				p {
					display: inline;
				}

				span {
					color: rgb(255, 211, 34);
				}
			}

			.key_list {
				width: 100%;
				margin-top: 20px;
				
				.key_item {
					width: 100%;
					height: 45px;
					display: flex;
					justify-content: center;
					align-items: center;

					p {
						font-size: 12px;
						color: #fff;

						span {
							color: rgb(255, 211, 34);
							font-weight: bold;
						}
					}

					i {
						font-style: normal;
						color: #fff;
						margin: 0 20px;
					}

					span {
						color: rgb(255, 211, 34);
						font-size: 12px;

						img {
							width: 26px;
							vertical-align: middle;
							margin-bottom: 2px;
							margin-left: 2px;
							margin-right: 2px;
						}
					}
				}
			}
		}

		.Grade {
			width: 100%;
			margin-top: 12px;
			padding: 0 10px 15px 20px;
			background: #1D1D28;
			border-radius: 4px;
			overflow: hidden;
			color: #fff;
			box-sizing: border-box;

			.Grade_tit {
				font-size: 16px;
				font-weight: bold;
				text-align: center;
				margin: 10px 0;
			}

			.Grade_item {
				width: 100%;
				display: flex;
				height: 40px;

				&:nth-child(2n) {
					// background: linear-gradient(90deg, rgba(255, 162, 24, 0), rgba(255, 162, 24, .19) 52%, rgba(255, 162, 24, 0));
				}

				&>div {
					flex: 1;
					font-size: 14px;
					display: flex;
					align-items: center;

					.coins {
						width: 14px;
						height: 14px;
						margin-right: 2px;
					}

				}

				&:nth-child(2) {
					background: none;

					p {
						font-size: 12px;
					}
				}
			}
		}

		.Subordinate {
			width: 100%;
			margin-top: 12px;
			padding: 0 10px 20px;
			background: #1D1D28;
			border-radius: 4px;
			overflow: hidden;
			color: #fff;
			box-sizing: border-box;
			.Subordinate_tit {
				font-size: 16px;
				font-weight: bold;
				text-align: center;
				margin: 10px 0;
			}

			.Subordinate_list {
				width: 100%;
				// padding-left: 20px;
				box-sizing: border-box;
				margin-bottom: 15px;
				// min-height: 100px;
				table{
					width: 100%;
					tr{
						td{
							padding: 10px 0;
							// text-align: center;
							.price{
								@include Scenter;
							}
							.coins {
								width: 14px;
								height: 14px;
								margin-right: 2px;
							}
						}
					}
				}

			}
		}
	}
	.type-list{
		display: flex;
		margin-bottom: 5px;
		justify-content: center;
		.item{
			height: 32px;
			width: 112px;
			text-align: center;
			line-height: 32px;
			background: linear-gradient(180deg, #23232B 0%, #223 100%);
			font-size: 12px;
			cursor: pointer;
			color: #B2B2B2;
			margin: 0 4px;
			&.on{
				color: #FFB800;
			}
		}
	}
</style>
